<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Add a brand image</h1>
                < ! - - <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> - - >
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Add a brand image</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">

                            <p>
                                Give the image a name. For example, "Logo - summer edition".
                            </p>

                            <!-- <v-select outlined dense :items="newImageTypeChoices" v-model="newImageType" color="green" label="Type" required hint="The kind of data to which this image will be applied">
                            </v-select> -->

                            <v-text-field
                                ref="newImageLabelInput"
                                v-model="newImageLabel"
                                label="Label"
                                :rules="newImageLabelRules"
                                validate-on-blur
                                color="green"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <p>How will this image be used?</p>

                            <v-select :items="intentChoices" v-model="newImageIntent" label="Intent" outlined dense>
                                <!-- <template #append-outer>
                                    <v-btn icon color="green" @click="saveEditPaletteMode">
                                        <font-awesome-icon :icon="['fas', 'check']"/>
                                    </v-btn>
                                    <v-btn icon color="grey" @click="isEditingPaletteMode = false">
                                        <font-awesome-icon :icon="['fas', 'times']"/>
                                    </v-btn>
                                </template> -->
                            </v-select>

                            <p>Will this image be in Light Mode or Dark Mode?</p>

                            <v-select :items="modeChoices" v-model="newImageMode" label="Ambience" outlined dense>
                                <!-- <template #append-outer>
                                    <v-btn icon color="green" @click="saveEditPaletteMode">
                                        <font-awesome-icon :icon="['fas', 'check']"/>
                                    </v-btn>
                                    <v-btn icon color="grey" @click="isEditingPaletteMode = false">
                                        <font-awesome-icon :icon="['fas', 'times']"/>
                                    </v-btn>
                                </template> -->
                            </v-select>

                            <!-- <v-text-field
                                ref="imageCommentInput"
                                v-model="newImageComment"
                                label="Comment"
                                hint="This is just for you to remember how you are using the image"
                                :rules="newImageCommentRules"
                                validate-on-blurR
                                color="green"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field> -->

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Continue</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        typeList: [],

        account: null,

        error: null,
        image: null,
        // create form
        createImageForm: null,
        submitFormTimestamp: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newImageLabel: null,
        newImageLabelRules: [
            (v) => !!v || 'Label is required',
        ],

        newImageMode: 'light',
        modeChoices: [
            { text: 'Light Mode', value: 'light' },
            { text: 'Dark Mode', value: 'dark' },
        ],

        newImageIntent: 'logo_square',
        intentChoices: [
            // type only
            { header: 'Logotype only - stylized brand name' },
            { text: 'Logotype', value: 'logotype' },
            // { text: 'Rectangular Logotype (H) - Stylized brand name', value: 'logotype_rect_h' }, // logotype only, typically horizontal for Roman script, full size logomark and logotype image fit into a square
            // { text: 'Rectangular Logotype (V) - Stylized brand name', value: 'logotype_rect_v' }, // logotype only, typically vertical for Japanese script, full size logomark and logotype image fit into a square

            // mark only
            { header: 'Logomark only - stylized brand symbol' },
            { text: 'Logomark', value: 'logomark' },
            // { text: 'Rectangular Logomark (H) - Stylized brand symbol', value: 'logomark_rect_h' }, // logomark only, typically horizontal
            // { text: 'Rectangular Logomark (V) - Stylized brand symbol', value: 'logomark_rect_v' }, // logomark only, typically horizontal
            // { text: 'Square Logomark - Stylized brand symbol', value: 'logomark_square' }, // logomark only, fit into a square shape

            // complete logo
            { header: 'Logo - Stylized brand name and symbol' },
            { text: 'Logo (H) - Adjacent logomark and logotype', value: 'logo_h' },
            { text: 'Logo (V) - Stacked logomark and logotype', value: 'logo_v' },
            // { text: 'Rectangular Logo (H) - Adjacent logomark and logotype', value: 'logo_rect_h' },  // where the logomark (image) and logotype (brand name) are arranged horizontally (adjacent)
            // { text: 'Rectangular Logo (V) - Stacked logomark and logotype', value: 'logo_rect_v' }, // where the logomark (image) and logotype (brand name) are arranged vertically (stacked)
            // { text: 'Square Logo', value: 'logo_square' }, // full size logomark and logotype image fit into a square

            // logo icon - typically a simplified version of the logomark fit into a square shape
            { header: 'Icon - Brand symbol fit into a square or circle' },
            { text: 'Square Icon', value: 'icon_square' },
            { text: 'Rounded Square Icon', value: 'icon_square_rounded' },
            { text: 'Circular Icon', value: 'icon_circular' },

            // banners
            { header: 'Banner - Brand name and symbol in a large rectangle' },
            { text: 'Rectangular Banner (H)', value: 'banner_rect_h' },
            { text: 'Rectangular Banner (V)', value: 'banner_rect_v' },

            // backgrounds (typically rectangular)
            { header: 'Background - Brand themed background image' },
            { text: 'Header Background', value: 'background_header' },
            { text: 'Footer Background', value: 'background_footer' },
        ],

        // newImageType: null,
        // newImageTypeChoices: [], // will be loaded from server
        newImageComment: null,
        newImageCommentRules: [
            // (v) => !!v || 'comment is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        isFormComplete() {
            return typeof this.newImageLabel === 'string' && this.newImageLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    watch: {
        focus() {
            setTimeout(() => { this.$nextTick(() => this.$refs.newImageLabelInput.focus()); }, 1);
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            this.createImage();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createImage() {
            try {
                this.error = false;
                this.$store.commit('loading', { createImage: true });
                console.log('createImage');
                const request = {
                    brand_id: this.$route.query.brand_id,
                    intent: this.newImageIntent,
                    mode: this.newImageMode,
                    label: this.newImageLabel,
                    // type: this.newImageType,
                    // comment: this.newImageComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).image.create(request);
                console.log('createImage response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-image', params: { accountId: this.$route.params.accountId, imageId: id }, query: { brand_id: this.$route.query.brand_id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create image' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create image' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create image', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create image' });
            } finally {
                this.$store.commit('loading', { createImage: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadTypeList();
        setTimeout(() => { this.$nextTick(() => this.$refs.newImageLabelInput.focus()); }, 1);
    },
};
</script>
